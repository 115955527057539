<script setup>
    import { reactive } from 'vue'
    import { useRoute } from 'vue-router'
    import { updatePassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue, query, equalTo, orderByChild } from "firebase/database";
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import HeaderComponent from '../components/HeaderComponent.vue'
    import axios from 'axios';
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const route = useRoute()
    const reactiveObj = reactive({
        //現在のメールアドレス
        email: "",
        //現在のパスワード
        password: "",
        //新しいパスワード
        newPassword1: "",
        //新しいパスワード（確認）
        newPassword2: "",
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //モーダルに関する特殊操作に用いる
        optionFlag: false,
        //
        loadingFlag: false
    })
    // ログアウト処理
    const logOut = () => {
        // ユーザー情報とイベント内容をログに記録
        signOut(auth)
        .then(() => {})
        .catch((error) => {
            alert(Error.errorResult('logout error', auth.currentUser))
        });
    }
    const pushEmail = (email) => {
        return new Promise((resolve) => {
            axios({
                baseURL: process.env.VUE_APP_PUSHMAIL,
                method: 'post',
                params: {
                    email: email,
                }
            })
            .then((res)=>{
                //console.log(res)
                resolve(true)
            })
            .catch((e)=>{
                //resolve(false)
            })
        })
    }
    const checkCorporate = () => {
        return new Promise((resolve) => {
			const getData = databaseRef(database, `CorporateId/${route.query.CorporateId}/User`);
            const checkData = query(getData, orderByChild("Email"), equalTo(reactiveObj.email))
            onValue(checkData, (snapshot) => {
                resolve(snapshot.val()? true : false)
            })
		})
    }
    const showDefaultModal = (message, agreeFlag = false) => {
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2 || agreeFlag){
            //モーダルを表示
            reactiveModalObj.modalFlag= true
            //モーダル上の表示する文面
            reactiveModalObj.modalMessage = message ? message : `入力した内容でパスワードを変更します。<br><br>よろしいですか？`
            //モーダル部分を再描画
            reactiveModalObj.modalRenderkey++
        }
        else {
            alert(Error.errorResult('password mismatch', auth.currentUser))
        }
    }
    const closeDefaultModal = (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag && !reactiveModalObj.optionFlag) {
            signInWithEmailAndPassword(auth, reactiveObj.email, reactiveObj.password)
            // サインイン成功時
            .then(async (userCredential) => {
                if(!await checkCorporate()) {
                    alert(Error.errorResult('url or corporate mismatch', auth.currentUser))
                    reactiveModalObj.loadingFlag = false
                }
                else {
                    const user = userCredential.user;
                    const newPassword = reactiveObj.newPassword1
                    //処理中
                    updatePassword(user, newPassword)
                    .then(() => {
                        reactiveModalObj.loadingFlag = false
                        showDefaultModal(`パスワードを変更しました。`, true)
                        pushEmail(reactiveObj.email)
                        reactiveModalObj.optionFlag = true
                        reactiveObj.email= ""
                        reactiveObj.password = ""
                        reactiveObj.newPassword1 = ""
                        reactiveObj.newPassword2 = ""
                    })
                    .catch((error) => {
                        reactiveModalObj.loadingFlag = false
                        alert(Error.errorResult('password update failed', auth.currentUser))
                    });
                }
                logOut()
            })
            // サインイン失敗時
            .catch((error) => {
                reactiveObj.errorMessage = Error.errorResult(error.code, auth.currentUser)
            });
        }
        if(!reactiveModalObj.optionFlag)
        reactiveModalObj.loadingFlag = true
    }
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <main>
        <link rel="stylesheet" href="./togglePassword.css">
        <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        <div id="loadingDisplay" class="loading" v-show="reactiveModalObj.loadingFlag">
            <div class="load-text load-blink">処理中...</div>
        </div>
        <div class="userPassword">
            <div class="titleArea">
                <p class="title">利用者のパスワード変更</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　登録メールアドレス</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="text" class="hideText" v-model="reactiveObj.email">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　現在のパスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.password">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しいパスワード（確認）</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea notButtom"><button type="button" id="commonButton" @click="showDefaultModal()">変更する</button></td>
                    </tr>
                    <tr>
                        <td class="notTop"><router-link :to="{name: 'userpasswordreset', query: {CorporateId: route.query.CorporateId}}">パスワードを忘れた方はこちら</router-link></td>
                    </tr>
                </tbody>
            </table>
            <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefaultModal"></DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .userPassword {
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child {
        border-radius: 20px 20px 0 0;
    }
    .commonTable td {
        height: 75px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    @media (max-width: 1000px) {
        .userPassword {
            padding: 0 5% 0 5%;
        }
        #commonButton {
            width: 30vw;
            left: 17%;
        }
    }
</style>