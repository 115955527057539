<script setup>
    import { signOut } from "firebase/auth";
    import { useRouter } from 'vue-router'
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const auth = Firebase.auth
    const router = useRouter()
    const confirmLogout = () => {
        const result = confirm('ログアウトしますか？')
        if(!result) {
            return
        }
        logOut()
    }
    const logOut = () => {
        // ユーザー情報とイベント内容をログに記録
        signOut(auth).then(() => {
            router.push('/login')
            alert('ログアウトしました')
        }).catch((error) => {
            alert(Error.errorResult('logout error', auth.currentUser))
        });
    }
    const exitNavigation = () => {
        document.getElementById('menuBtnCheck').checked = false;
    }
</script>
<template>
    <!-- 縦型ナビゲーションバー -->
    <div class='navigationList'>
        <input type="checkbox" id="menuBtnCheck">
        <label for="menuBtnCheck" class="menuBtn"><span></span></label>
        <div class="serviceName">
            <p>ProoFace</p>
        </div>
        <div class="menuContent">
            <ul>
                <li><router-link class="nav-link" to="/" active-class="link--active">端末状況</router-link></li>
                <li><router-link class="nav-link" to="/userregistration" active-class="link--active">利用者登録</router-link></li>
                <li><router-link class="nav-link" to="/appsettings" active-class="link--active">アプリ設定</router-link></li>
                <li><router-link class="nav-link" to="/entryexitlogs" active-class="link--active">入退室ログ</router-link></li>
                <li><router-link class="nav-link" to="/adminpassword" active-class="link--active">管理パスワード変更</router-link></li>
                <li><router-link class="nav-link" to="/request" active-class="link--active">プラン変更・退会申請</router-link></li>
                <li><a @click="confirmLogout">ログアウト</a></li>
            </ul>
        </div>
        <div id="navigationOverlay" @click.self="exitNavigation()"></div>
    </div>
</template>

<style scoped>
    .navigationList{
        margin: 60px 0 0 60px;
        position: fixed;
        width: 15%;
        z-index: 4;
    }
    #menuBtnCheck {
        display: none;
    }
    .serviceName p {
        top: -20%;
        left: -5%;
        position: absolute;
        font-size: 35px;
        padding: 0 0 0 10px;
        font-weight: bold;
    }
    .navigationList ul{
        list-style-type: none;
    }
    .navigationList li:first-child a{
        border:1px solid;
        border-color: #e7e6e6;
        border-radius: 15px 15px 0 0;
    }
    .navigationList li:last-child a{
        border:1px solid;
        border-color: #e7e6e6;
        border-radius: 0 0 15px 15px;
    }
    .navigationList li a{
        text-decoration: none;
        font-size: 100%;
        display: block;
        width: 100%;
        height: 100%;
        padding: 10% 10%;
        border:1px solid;
        border-color: #e7e6e6;
        background: #F5F5F5;
        box-sizing: border-box;
        cursor: pointer;
    }
    .navigationList li a:hover {
        background-color: #00AEEB;
        color: #F5F5F5;
    }
    .navigationList li .link--active {
        background-color: #00AEEB;
        color: #F5F5F5;
    }
    @media (max-width: 1000px) {
        .serviceName {
            display: none;
        }
        .menuBtn {
            position: fixed;
            top: 10px;
            left: 10px;
            display: flex;
            height: 60px;
            width: 60px;
            justify-content: center;
            align-items: center;
            z-index: 9;
            background-color: #00AEEB;
        }
        .menuBtn span,
        .menuBtn span:before,
        .menuBtn span:after {
            content: '';
            display: block;
            height: 3px;
            width: 25px;
            border-radius: 3px;
            background-color: #ffffff;
            position: absolute;
        }
        .menuBtn span:before {
            bottom: 8px;
        }
        .menuBtn span:after {
            top: 8px;
        }
        #menuBtnCheck:checked ~ .menuBtn span {
            background-color: rgba(255, 255, 255, 0);
        }
        #menuBtnCheck:checked ~ .menuBtn span::before {
            bottom: 0;
            transform: rotate(45deg);
            transition: all 0.5s;
        }
        .menuBtn span::before {
            transform: rotate(0deg);
            transition: all 0.5s;
        }
        #menuBtnCheck:checked ~ .menuBtn span::after {
            top: 0;
            transform: rotate(-45deg);
            transition: all 0.5s;
        }
        .menuBtn span::after {
            transform: rotate(0deg);
            transition: all 0.5s;
        }
        .menuContent {
            width: 300px;
            height: 100%;
            position: fixed;
            top: 0;
            left: -300px;
            z-index: 8;
            background-color: #ffffff;
            transition: all 0.5s;
            overflow-y: scroll;
        }
        #navigationOverlay {
            width: 100%;
            z-index: 7;
            height: 100%;
            top: 0;
            left: -100%;
            position: fixed;
            transition: all 0.5s;
        }
        .menuContent ul {
            padding: 70px 10px 0;
        }
        .menuContent ul li {
            border-bottom: solid 1px #ffffff;
            list-style: none;
        }
        #menuBtnCheck:checked ~ .menuContent {
            left: 0;/*メニューを画面内へ*/
        }
        #menuBtnCheck:checked ~ #navigationOverlay {
            left: 0;/*メニューを画面内へ*/
        }
    }
</style>
