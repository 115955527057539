<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import {onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue, update } from "firebase/database";
    import { ref as storageRef, uploadBytes } from "firebase/storage";
    import HeaderComponent from '../components/HeaderComponent.vue'
    import NavigationBar from '../components/NavigationBar.vue'
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const storage = Firebase.storage;

    const reactiveObj = reactive({
        //利用者の姓名を利用するかを判別するフラグ
        UseName1: false,
        //利用者のセイメイを利用するかを判別するフラグ
        UseName2: false,
        //企業名
        CorporateName: "",
        //企業ロゴファイル名
        LogoPath: "",
        //端末側のタイムアウト時間
        Timeout: 10,
        //最終更新日時
        LatestUpdated: 0,
    })
    const reactiveRender = reactive({
        //再描画用のキー
        renderKey: 0
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0
    })
    let beforeInfo = {}
    let corporateId = ""
    let logofile = 0
    //firebaseからアプリ情報を取得
    const getSettings = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const getData = databaseRef(database, `LoginUser/${user.uid}`)
                onValue(getData, (snapshot) => {
                    corporateId = snapshot.val()["CorporateId"]
                    const settingsRef = databaseRef(database, `CorporateId/${corporateId}/AppSettings`)
                    //firebaseからアプリ情報を取得する
                    onValue(settingsRef, (snapshot) => {
                        const settings = (snapshot.val())
                        reactiveObj.CorporateName = settings["CorporateName"]
                        reactiveObj.UseName1 = settings["UseName1"]
                        reactiveObj.UseName2 = settings["UseName2"]
                        reactiveObj.LogoPath = settings["LogoPath"]
                        reactiveObj.Timeout = settings["Timeout"]
                        reactiveObj.LatestUpdated = settings["LatestUpdated"]
                        beforeInfo = settings
                    })
                })
            } else {
            }
        })
    }
    //画像アップロード処理
    const imageUpload = (e) => {
        const logo = e.target.files
        //対応拡張子を.jpeg .jpg .pngにバリデーション
        const regexp = new RegExp(/.*\.jpg$|.*\.jpeg$|.*\.png$/, 'i')
        //サイズチェック(10MB以下)
        try {
            //拡張子チェッカー
            if(regexp.test(logo[0]['name']) && logo[0]['size'] <= 10485760) {
                //アップロード可能な画像がある場合、ラベル名を変更
                document.getElementById("imageLabel").innerHTML = '選択済';
                inputSomeData(logo[0]['name'], 'LogoPath')
                logofile = logo[0]
            }
            else {
                alert(Error.errorResult('image format error', auth.currentUser))
                document.getElementById("imageLabel").innerHTML = '画像選択';
                document.getElementById('commonButton').disabled = true
                reactiveRender.renderKey++
                return
            }
        }
        catch {
            alert(Error.errorResult('image reading error', auth.currentUser))
            document.getElementById("imageLabel").innerHTML = '画像選択';
            document.getElementById('commonButton').disabled = true
            reactiveRender.renderKey++
            return
        }
    }
    const inputSomeData = (data, type) => {
        reactiveObj[type] = data
        let equalFlag = false
        for(let key in reactiveObj){
            if(beforeInfo[key] != reactiveObj[key] && key != 'LatestUpdated' || document.getElementById("imageLabel").innerHTML == '選択済'){
                equalFlag = true
            }
            equalFlag ? document.getElementById('commonButton').disabled = false: document.getElementById('commonButton').disabled = true
        }
    }
    const getUseName1 = computed({
        get: () => { return reactiveObj.UseName1},
        set: (value) => { inputSomeData(value, 'UseName1') }

    })
    const getUseName2 = computed({
        get: () => { return reactiveObj.UseName2},
        set: (value) => { inputSomeData(value, 'UseName2') }
    })
    const getCorporateName = computed({
        get: () => { return reactiveObj.CorporateName},
        set: (value) => { inputSomeData(value, 'CorporateName') }

    })
    const getTimeout = computed({
        get: () => { return reactiveObj.Timeout},
        set: (value) => { inputSomeData(Number(value), 'Timeout') }

    })
    const showDefaultModal = (message) => {
        //モーダルを表示
        reactiveModalObj.modalFlag= true
        //モーダル上の表示する文面
        reactiveModalObj.modalMessage = message
        //モーダル部分を再描画
        reactiveModalObj.modalRenderkey++
    }
    const closeDefaultModal = async (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag) {
            if(document.getElementById("imageLabel").innerHTML == '選択済') {
                const metadata = {
                    contentType: reactiveObj.LogoPath.match(/.*\.jpg$|.*\.jpeg$/i) ? 'image/jpeg':
                        reactiveObj.LogoPath.match(/.*\.png$/i) ? 'image/png' : ""
                };
                const uploadPath = `/${corporateId}/Logo/`
                const uploadref = storageRef(storage, `${uploadPath}${reactiveObj.LogoPath}`)
                //画像をアップロード
                await uploadBytes(uploadref, logofile, metadata)
            }
            document.getElementById('commonButton').disabled = false
            document.getElementById("imageLabel").innerHTML = '画像選択';
            reactiveRender.renderKey++

            const settingsRef = databaseRef(database, `CorporateId/${corporateId}/AppSettings`)
            reactiveObj.LatestUpdated = Date.now()
            update(settingsRef, reactiveObj);
            //showDefaultModal('変更が完了しました')
        }
    }
    onMounted(() => {
        getSettings()
    })

</script>

<template>
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div class="appSettings" :key="reactiveRender.renderKey">
            <div class="titleArea">
                <p class="title">アプリ設定</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th class="firstTd halfTh"></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd">　利用者の姓名を利用する</td>
                        <td class="firstTd">
                            <div class="switchArea">
                                <p class="switchLabel" v-if = "reactiveObj.UseName1">ON</p>
                                <p class="switchLabel" v-else>OFF</p>
                                <label>
                                    <input type="checkbox" v-model="getUseName1">
                                    <div id="swImg"></div>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd">　利用者の姓名(フリガナ)を利用する</td>
                        <td class="firstTd">
                            <div class="switchArea">
                                <p class="switchLabel" v-if = "reactiveObj.UseName2">ON</p>
                                <p class="switchLabel" v-else>OFF</p>
                                <label>
                                    <input type="checkbox" v-model="getUseName2">
                                    <div id="swImg"></div>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　企業ロゴ・企業名</td>
                        <td class="firstTd notButtom"><label id="imageLabel">画像選択<input type="file" id="imageButton" @change="imageUpload"></label></td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop"><input type="text" id="inputCorporate" v-model="getCorporateName" placeholder="企業名"></td>
                        <td class="firstTd notTop"></td>
                    </tr>
                    <tr>
                        <td class="firstTd">　タイムアウト時間</td>
                        <td class="firstTd">
                            <select id="timeoutButton" v-model="getTimeout">
                                <option value="1">1分</option>
                                <option value="3">3分</option>
                                <option value="5">5分</option>
                                <option value="10">10分</option>
                                <option value="20">20分</option>
                                <option value="30">30分</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea notButtom"><button id="commonButton" @click="showDefaultModal(`入力した内容で設定します。<br>よろしいですか？`)" disabled>設定する</button></td>
                        <td class="notButtom"></td>
                    </tr>
                </tbody>
            </table>
            <DefaultModalComponent
                :key="reactiveModalObj.modalRenderkey"
                :modalMessage="reactiveModalObj.modalMessage"
                v-show="reactiveModalObj.modalFlag"
                @executeMethod="closeDefaultModal">
            </DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .appSettings {
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    .commonTable td {
        height: 80px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    .switchArea {
        position: relative;
        display: inline-block;
        width: 50%;
        background: #EEEEEF;
        height: 60%;
        left: 45%;
        border: solid 2px #999999;
        border-radius: 10px;
    }
    .switchArea input[type="checkbox"] {
        display: none;
    }
    .switchArea label {
        display: block;
        box-sizing: border-box;
        height: 100%;
        border: 2px solid #EEEEEF;
        border-radius: 10px;
    }
    .switchArea p {
        position: absolute;
        height: 100%;
        left: -40px;
        top: 15%;
        border: none;
    }
    .switchArea #swImg {
        position: absolute;
        width: 45%;
        height: 70%;
        background: #999999;
        top: 15%;
        left: 3%;
        border-radius: 10px;
        transition: .2s;
    }
    .switchArea input[type="checkbox"]:checked ~ #swImg {
        transform: translateX(110%);
        background: #00AEEB;
    }

    #inputCorporate {
        width: 100%;
        margin: 0 0 0 15px;
        border-radius: 10px;
        border: none;
        background-color: #EEEEEF;
    }
    #imageLabel {
        display: block;
        position: relative;
        border-radius: 10px;
        border: solid 2px #999999;
        background-color: #EEEEEF;
        width: 50%;
        height: 60%;
        text-align: center;
        line-height: 300%;
        left: 45%;
    }
    #fileLabel {
        display: block;
        position: relative;
        border-radius: 10px;
        border: solid 2px #999999;
        background-color: #EEEEEF;
        width: 50%;
        height: 60%;
        text-align: center;
        line-height: 300%;
        left: 45%;
    }
    #fileButton {
        display: none;
    }
    #imageButton {
        display: none;
    }
    #timeoutButton {
        position: relative;
        border-radius: 10px;
        border: solid 2px #999999;
        background-color: #EEEEEF;
        width: 51%;
        height: 66%;
        margin: 0 4% 0 45%;
        text-align: center;
    }
    #commonButton {
        width: 50%;
        left: 75%;
    }
    @media (max-width: 1000px) {
        .appSettings {
            padding: 0 5% 0 5%;
        }
        .firstTd {
            font-size: small;
        }
        #inputCorporate {
            width: 180%;
        }
        .commonTable {
            width: 100%;
        }
        #commonButton {
            width: 75%;
            left: 90%;
        }
    }
</style>
