
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import { onAuthStateChanged, signOut } from "firebase/auth";
    import { useRouter } from 'vue-router'
    import { ref as databaseRef, onValue, update, push, child, set } from "firebase/database";
    import { formatToTimeZone } from 'date-fns-timezone';
    import HeaderComponent from "../components/HeaderComponent.vue"
    import EditTeminalComponent from "../components/EditTeminalComponent.vue"
    import AddTeminalComponent from "../components/AddTeminalComponent.vue"
    import NavigationBar from "../components/NavigationBar.vue"
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const FORMAT = 'YYYY/MM/DD HH:mm:ss';
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()

    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //ドア情報一覧
        terminalObjects: [],
        //子コンポーネントに渡すようのドア情報
        terminalObject: {},
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //端末追加用のモーダル
        addModalFlag: false,
        //端末編集用モーダル
        editModalFlag: false,
        //端末削除用モーダル
        deleteModalFlag: false,
        //削除用モーダルメッセージ
        modalMessage: "",
        //削除用モーダルメッセージ(赤字)
        modalRedMessage: "",
        //主に削除用に使用
        otherObject: {}
    })
    //1ページに表示する件数
    const perPage = 10;
    //firebaseからログインユーザーの情報を取得
    const getTerminals = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const getData = databaseRef(database, `LoginUser/${user.uid}`)
                onValue(getData, (snapshot) => {
                    try {
                        reactiveObj.corporateId = snapshot.val()["CorporateId"] }
                    catch {
                        signOut(auth)
                        .then(() => {
                            router.push('/login')
                        })
                        .catch((error) => {});
                    }
                    const terminalsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals`)
                    //firebaseからドア情報を取得する
                    onValue(terminalsRef, (snapshot) => {
                        reactiveObj.terminalObjects = []
                        const terminals = (snapshot.val())
                        for (let key in terminals) {
                            reactiveObj.terminalObjects.push({
                                'terminalId': key,
                                'TerminalName': terminals[key]['TerminalName'],
                                'Location': terminals[key]['Location'],
                                'LockTime': terminals[key]['LockTime'],
                                'TerminalType': terminals[key]['TerminalType'],
                                'TerminalPair': terminals[key]['TerminalPair'],
                                'Connection': terminals[key]['Connection'],
                                'DeviceType': terminals[key]['DeviceType'],
                                'DisplayLatestUpdated': terminals[key]["DisplayLatestUpdated"] ? formatToTimeZone(terminals[key]["DisplayLatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO }) : "",
                                'EntryExit': terminals[key]['EntryExit'],
                                'LatestUpdated': formatToTimeZone(terminals[key]["LatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO })
                            })
                        }
                    })
                    reactiveObj.renderKey++
                })
            } else {
            }
        })
    }
    //n個目のモーダルを表示する
    const showEditModal = (data, key) => {
        reactiveObj.editModalFlag = true
        reactiveObj.terminalObject = data
    }
    //モーダルを閉じる
    const closeEditModal = async (editFlag, editTerminalObject) => {
        //n個目のモーダルを閉じる
        reactiveObj.editModalFlag = false
        //更新対象の判別
        if(editFlag) {
            if(editTerminalObject['editTerminalInfo']['TerminalType'] == 'single' && reactiveObj.terminalObject['TerminalPair']){
                alert(Error.errorResult('not single', auth.currentUser))
                return
            }
            else if(editTerminalObject['editTerminalInfo']['TerminalType'] != 'single' && !reactiveObj.terminalObject['TerminalPair']){
                alert(Error.errorResult('not pair', auth.currentUser))
                return
            }
            else {
                const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${editTerminalObject['terminalId']}`);
                await update(terminalData, editTerminalObject['editTerminalInfo']);
                const editTerminalPairInfo = {
                    'TerminalName': editTerminalObject['editTerminalInfo']['TerminalName'],
                    'Location': editTerminalObject['editTerminalInfo']['Location'] == '外側' ? '内側' : '外側',
                    'LockTime': editTerminalObject['editTerminalInfo']['LockTime'],
                    'TerminalType': editTerminalObject['editTerminalInfo']['TerminalType'] == 'parent' ? 'child' :
                        editTerminalObject['editTerminalInfo']['TerminalType'] == 'child' ? 'parent' : 'single',
                    'EntryExit': editTerminalObject['editTerminalInfo']['EntryExit'] == '入室' ? '退室' : '入室',
                    'LatestUpdated': editTerminalObject['editTerminalInfo']['LatestUpdated']
                }
                const terminaPairlData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObject.TerminalPair}`);
                await update(terminaPairlData, editTerminalPairInfo);
            }
        }
    }
    const showAddModal = () => {
        reactiveObj.addModalFlag= true
    }
    const closeAddModal = (editFlag, newTeminalObj) => {
        reactiveObj.addModalFlag= false
        if(editFlag){
            const locationMap = {
                "内側": "退室",
                "外側": "入室"
            }
            let parentId = ""
            let childId = ""
            const terminalPath = `CorporateId/${reactiveObj.corporateId}/Terminals`
            for(let i = 0; i < (newTeminalObj.TerminalType == 'parent' ? 2 : 1); i++){
                const newTeminalKey = push(child(databaseRef(database), terminalPath)).key;
                i == 0 ? parentId = newTeminalKey : childId = newTeminalKey
                const data = {
                    "AllowViewAccount": [],
                    "Connection": false,
                    "DeviceType": "Sesame",
                    "LatestUpdated": Date.now(),
                    "DisplayLatestUpdated": 0,
                    "Location": i == 0 ? newTeminalObj.Location :
                        newTeminalObj.Location == "内側" ? "外側" : "内側",
                    "LockTime": Number(newTeminalObj.LockTime),
                    "PermittedGroupId": [
                        "All"
                    ],
                    "TerminalName": newTeminalObj.TerminalName,
                    "TerminalType": i == 0 ? newTeminalObj.TerminalType : 'child',
                    "EntryExit": i == 0 ? locationMap[newTeminalObj.Location] :
                        locationMap[newTeminalObj.Location == "内側" ? "外側" : "内側"]
                }
                update(databaseRef(database, `${terminalPath}/${newTeminalKey}`), data)
            }
            update(databaseRef(database, `${terminalPath}/${parentId}`), {"TerminalPair": childId})
            if(childId) update(databaseRef(database, `${terminalPath}/${childId}`), {"TerminalPair": parentId})
        }
    }
    const showDeleteModal = (data, key) => {
        reactiveObj.otherObject = {
            terminalIndex: key,
            confirm: '削除する',
            modalWidth: "35%",
        }
        reactiveObj.deleteModalFlag = true
        let TerminalType = data.TerminalType == 'parent'? '親機': data.TerminalType == 'child'? '子機' : '単独'
        reactiveObj.modalMessage = `以下の端末を削除します。<br>よろしいですか？<br><br>端末名：${data.TerminalName}<br>設置先：${data.Location}<br>親子設定：${TerminalType}`
        reactiveObj.modalRedMessage = data.TerminalType == 'parent' ? "※親機を削除すると、子機も同時に削除されます。" : ""
        reactiveObj.renderKey++
    }
    const closeDeleteModal = async (deleteFlag) => {
        const terminalIndex = reactiveObj.otherObject.terminalIndex
        reactiveObj.deleteModalFlag = false
        if(deleteFlag) {
            const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObjects[terminalIndex]['terminalId']}`);
            const terminaPairtlData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${reactiveObj.terminalObjects[terminalIndex]['TerminalPair']}`);
            switch (reactiveObj.terminalObjects[terminalIndex]['TerminalType']) {
                case 'parent':
                    if(reactiveObj.terminalObjects[terminalIndex]['TerminalPair'])
                        await set(terminaPairtlData, {})
                    break
                case 'child':
                    await update(terminaPairtlData, {
                        "TerminalPair": "",
                        "TerminalType": "single"
                    })
                    break
                case 'single':
                    break
            }
            await set(terminalData, {})
        }
        reactiveObj.otherObject = {}
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.terminalObjects.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.terminalObjects.length / perPage) ? Math.ceil(reactiveObj.terminalObjects.length / perPage) : 1
    })
    onMounted(() => {
        getTerminals()
    })
</script>
<template>
    <!-- コメントアウト用 -->
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <div class="terminalStatus" :key="`${reactiveObj.renderKey}-terminalStatus`">
            <div class="titleArea">
                <p class="title">端末状況</p>
                <button id="addButton" @click="showAddModal">端末追加</button>
                <AddTeminalComponent v-show="reactiveObj.addModalFlag" @executeMethod="closeAddModal"></AddTeminalComponent>
            </div>
            <div class="tableArea">
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th id="terminal" class="leftSideTh">端末名</th>
                            <th id="parent">親子設定</th>
                            <th id="displayLatest" class="leftSideTh">最終更新日時</th>
                            <th id="edit">編集</th>
                            <th id="delete">削除</th>
                        </tr>
                        <tr v-for="(data, key) in getItems" :key="key">
                            <td class="leftSideTd">{{ data.TerminalName }}({{ data.Location }})</td>
                            <td>{{ data.TerminalType == 'parent'? '親機': data.TerminalType == 'child'? '子機' : '単独' }}</td>
                            <td class="leftSideTd">{{ data.DisplayLatestUpdated  }}</td>
                            <td><button type="button" class="editButton" @click="showEditModal(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../assets/pencil.png" id="pencilIcon"></button></td>
                            <td><button type="button" class="deleteButton" @click="showDeleteModal(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../assets/delete.png" id="deletelIcon"></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <EditTeminalComponent
                :key="reactiveObj.renderKey"
                :terminalObject="reactiveObj.terminalObject"
                v-show="reactiveObj.editModalFlag"
                @executeMethod="closeEditModal">
            </EditTeminalComponent>
            <DefaultModalComponent
                :key="reactiveObj.renderKey"
                :modalMessage="reactiveObj.modalMessage"
                :modalRedMessage="reactiveObj.modalRedMessage"
                :otherObject="reactiveObj.otherObject"
                v-show="reactiveObj.deleteModalFlag"
                @executeMethod="closeDeleteModal">
            </DefaultModalComponent>

            <div class="paginationArea">
                <!--
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                    :next-text="reactiveObj.currentPage < parseInt(reactiveObj.terminalObjects.length / 10) + 1 ? '>' : ''"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
                -->
                <PaginateComponent
                    :page-count="getPageCount"
                    :click-handler="clickCallback"
                    :page-range="5"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
                </PaginateComponent>
            </div>
        </div>
    </main>
</template>

<style scoped>
    .terminalStatus{
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    #addButton {
        position: relative;
        display: flex;
        margin-left: auto;
        top: -40px;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
    .commonTable #terminal{
        width: 50%;
    }
    .commonTable #edit{
        width: 10%;
    }
    .commonTable #parrent{
        width: 10%;
    }
    .commonTable #displayLatest{
        width: 20%;
    }
    #naviTerminalStatusView {
        background-color: #32a4ea;
        color: #F5F5F5;
    }
    @media (max-width: 1000px) {
        .terminalStatus{
            padding: 0 5% 0 5%;
        }
        .tableArea {
            overflow-x: scroll;
        }
        .commonTable {
            width: 1000px;
        }
    }
</style>
