
const errorResult = (errorCode, currentUser, view) => {
    let message = ''
    //ログイン中のエラー
    if(currentUser) {
        switch (errorCode) {
            //管理Web上のエラー
            case 'auth/password-does-not-meet-requirements':
                message = '以下のパスワードの要件が満たされるものを入力してください。\n・6文字以上\n・パスワードは大文字が含まれていること\n・パスワードは小文字が含まれていること\n・パスワードは数字が含まれていること\n・パスワードに記号が含まれていること'
                break
            case 'auth/invalid-credential':
                message = '現在のパスワードが誤っています。'
                break
            case 'not admin user':
                message = '管理者ユーザーでないため、ログインできませんでした。'
                break
            case 'image format error':
                message = '対応している画像は10MBかつ以下の拡張子となり、ファイルサイズまたは拡張子が異なる画像は除外されます。\n.jpg .jpeg .png'
                break
            case 'image reading error':
                message = '読み取り中にエラーが発生しました。ページを更新して再度やり直してください。'
                break
            case 'password mismatch':
                message = '入力された新しいパスワードが一致しません。'
                break
            case 'not from or to date':
                message = '検索したい開始日と終了日を入力してください。'
                break
            case 'invalid from and to dates':
                message = '開始日と終了日が正しくありません。'
                break
            case 'failed to load data':
                message = 'データの読み込みに失敗しました。再度時間を置いて行ってください。'
                break
            case 'logout error':
                message = 'ログアウト処理でエラーが発生しました。'
                break
            case 'uid or corporate mismatch':
                message = '登録されている企業コードもしくはUIDが異なっております。'
                break
            case 'oobCode mismatch':
                message = 'oobCodeが正しくありません正規の手順で再度実行をしてください。'
                break
            case 'password update failed':
                message = 'パスワード更新に失敗しました。'
                break
            case 'none logs':
                message = '出力したいログを検索より指定してから実行してください。'
                break
            case 'not csv':
                message = 'CSVファイルをアップロードしてください。'
                break
            case 'not csv format':
                message = '正しい形式のCSVファイルをアップロードしてください。'
                break
            case 'none files':
                message = '先に「CSVファイル参照」もしくは「画像ファイル参照」より\n更新するファイルをアップロードしてください。'
                break
            case 'none data':
                message = '先に「利用者登録」よりすべての値の入力と画像ファイルをアップロードしてください。'
                break
            case 'not delete admin user':
                message = '管理者ユーザーは削除できません。'
                break
            case 'modify failed':
                message = '登録処理に失敗しました。再度やりなおしてください。'
                break
            case 'not single':
                message = '親子設定されている端末を単独に変更することはできません。端末追加より新規に作成してください。'
                break
            case 'not pair':
                message = '単独設定されている端末を親機/子機に変更することはできません。端末追加より新規に作成してください。'
                break

            default:
                message = `不明なエラーを検知しました。\nerrorCode:${errorCode}(1)`
                break
        }
    }
    else {
        switch (errorCode) {
            //ログイン時のエラー
            case 'auth/wrong-password':
            case 'auth/invalid-email':
            case 'auth/user-not-found':
            case 'auth/invalid-credential':
                message = 'ログインに失敗しました。IDまたはパスワードが間違っています。'
                break
            case 'auth/too-many-requests':
                message = '規定回数以上誤ったログイン情報が入力されました。\n一時的にこのアカウントのログインは無効になっています。'
                break
            //function系のエラー
            case 'failed Generate':
                message = '正常に処理が完了できませんでした最初からやり直してください。'
                break
            case 'url or corporate mismatch':
                message = '企業コードまたはURLが違います。再度、新規登録時のメールリンクからお試しください。'
                break
            case 'uid mismatch':
                message = 'UIDが異なっております、正しいUIDを入力してください。'
                break
            default:
                message = `不明なエラーを検知しました。\nerrorCode:${errorCode}(2)`
                break
        }
    }
    return message
}

export default { errorResult };
