<script setup>
    import { reactive, watch } from 'vue'
    import HeaderComponent from '../components/HeaderComponent.vue'
    import NavigationBar from '../components/NavigationBar.vue'
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'

    import {updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
    import Firebase from "../firebase_settings/index.js"
    import Error from "../firebase_settings/error.js"

    const auth = Firebase.auth

    const reactiveObj = reactive({
        //現在の管理パスワード
        password: "",
        //新しい管理パスワード
        newPassword1: "",
        //新しい管理パスワード（確認）
        newPassword2: "",
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0
    })
    const inputSomeData = () => {
        let equalFlag = false
        if(reactiveObj.password && reactiveObj.newPassword1 && reactiveObj.newPassword2) {
                equalFlag = true
        }
        equalFlag? document.getElementById('commonButton').disabled = false: document.getElementById('commonButton').disabled = true
    }
    const showDefaultModal = () => {
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2){
            reactiveModalObj.modalFlag= true
            reactiveModalObj.modalMessage = `入力した内容で管理パスワードを変更します。<br><br>よろしいですか？`
            reactiveModalObj.modalRenderkey++
        }
        else {
            alert(Error.errorResult('password mismatch', auth.currentUser))
        }
    }
    const closeDefaultModal = (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag) {
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(
                user?.email ?? '',
                reactiveObj.password
            )
            reauthenticateWithCredential(user, credential)
            // サインイン成功時
            .then(() => {
                const newPassword = reactiveObj.newPassword1
                updatePassword(user, newPassword)
                .then(() => {
                    alert('パスワードの更新が完了いたしました')
                    reactiveObj.password = ""
                    reactiveObj.newPassword1 = ""
                    reactiveObj.newPassword2 = ""
                })
                .catch((error) => {
                    alert(Error.errorResult(error.code, auth.currentUser))
                });
            })
            // サインイン失敗時
            .catch((error) => {
                alert(Error.errorResult(error.code, auth.currentUser))
            });
        }
    }
    watch(reactiveObj, () => {
        inputSomeData()
    })
</script>

<template>
    <HeaderComponent></HeaderComponent>
    <NavigationBar></NavigationBar>
    <main>
        <link rel="stylesheet" href="./togglePassword.css">
        <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        <div class="adminPassword">
            <div class="titleArea">
                <p class="title">管理パスワード</p>
            </div>
            <table class="commonTable">
                <tbody>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　現在の管理パスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.password">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しい管理パスワード</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="firstTd notButtom">　新しい管理パスワード（確認）</td>
                    </tr>
                    <tr>
                        <td class="firstTd notTop">
                            <input type="checkbox" class="checkPassword">
                            <div class="togglePassword">
                                <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="updateArea notButtom"><button type="button" id="commonButton" @click="showDefaultModal()" disabled>変更する</button></td>
                    </tr>
                </tbody>
            </table>
            <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefaultModal"></DefaultModalComponent>
        </div>
    </main>
</template>

<style scoped>
    .adminPassword{
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    .commonTable td {
        height: 75px;
    }
    .commonTable .updateArea {
        height: 150px;
    }
    .commonTable tr:first-child>*:last-child{
        border-radius: 20px 20px 0 0;
    }
    #commonButton {
        width: 25%;
        height: 50%;
        top: 25%;
        left: 12.5%;
    }
    @media (max-width: 1000px) {
        .adminPassword{
            padding: 0 5% 0 5%;
        }
        #commonButton {
            width: 30vw;
            left: 17%;
        }
        .commonTable {
            width: 100%;
        }
    }
</style>
